import image12 from "../assets/img/image-12.png"
import image15 from "../assets/img/image-15.png"

export const LAB_AR = [
  {
    id: "1",
    title: "مختبر DigiPattern",
    heroTitle: "صفحة مختبر DigiPattern",

    description:
      "منذ إنشاء مكتب تصميم DigiPattern Lab الخاص بنا في عام 2014، قمنا بدعم العديد من العملاء في أعمال البحث والتطوير وتطوير مفاهيم جديدة...",
    remarque:
      "يتمتع موظفونا بفرصة العمل في مشاريع في طليعة الابتكار...",
    achievements: [
      {
        title: "الواقع المختلط والمعزز",
        description:
          "تطوير تطبيق الواقع المختلط مع تحميل النماذج المترجمة مسبقًا",
        image: image12,
        link: "/2",
      },
      {
        title: "لوحة تحكم الأندرويد",
        description:
          "تطوير تطبيق الواقع المختلط مع تحميل النماذج المترجمة مسبقًا",
        image: image12,
        link: "/2",
      },
    ],
  },
  {
    id: "2",
    title: "مركز الابتكار",
    heroTitle: "صفحة مركز الابتكار",

    description:
      "لقد كان مركز الابتكار لدينا مركزًا للتميز في التقنيات الجديدة، مما يتيح للشركات الناشئة والشركات التعاون...",
    remarque:
      "نحن نساعد عملائنا باستمرار على البقاء في المقدمة في العصر الرقمي من خلال تقديم حلول للتغيرات التكنولوجية الجديدة والمقبلة.",
    achievements: [
      {
        title: "اللافتات الرقمية",
        description:
          "تطوير تطبيق الواقع المختلط مع تحميل النماذج المترجمة مسبقًا",
        image: image12,
        link: "/1",
      },
      {
        title: "البث الجوي",
        description:
          "تطوير تطبيق الواقع المختلط مع تحميل النماذج المترجمة مسبقًا",
        image: image12,
        link: "#",
      },
    ],
  },
];

export const JOB_OFFERS_AR = [
        {
            imageUrl: " ",
            title: "مبرمج java",
            subtitle: "cdi",
            content: ".."
        },
        {
            imageUrl: " ",
            title:"مبرمج java",
            subtitle: "cdi",
            content: ".."
        }
];

export const OUR_HISTORY_AR = [
    {
        title: "2024",
        imageUrl: " ",
        subtitle: " ",
        content: ".."
    },
    {
        title: "2024",
        imageUrl: " ",
        subtitle: " ",
        content: ".."
    }
];

export const THE_FOUNDERS_AR = [
    {
        title: "المدير المشارك للشركة",
        subtitle: "عبدالله رسمكي",
        imageUrl: " ",
        content: "مطور برامج JS"
    },
    {
        title: "المدير المشارك للشركة",
        subtitle: "جمال علال",
        imageUrl: image15,
        content: "مطور برامج ++C"
    }
];
