import React from "react";
import "./Achievements.css";
import { Link } from "react-router-dom";
import { t } from "i18next";

const Achievements = ({ achievements }) => {
  return (
    <div className="container">
      <h2 className="text-primary my-4">{t("EXAMPLE_OF_ACHIEVEMENTS")}</h2>
      <div className="row">
        {achievements?.map((item, index) => (
          <div key={index} className="col-md-6 mb-4 achievement-card">
            <div className="">
              <h3>{item.title}</h3>
              <img src={item.image} alt={item.title} />
              <p>
                {item.description} <a href={item.link}>Read more</a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;
