import image12 from "../assets/img/image-12.png"
import image15 from "../assets/img/image-15.png"

export const LAB_EN = [
  {
    id: "1",
    title: "DigiPattern Lab",
    heroTitle: "DigiPattern Lab Page",

    description:
      "Since the creation of our DigiPattern Lab design office in 2014, we have supported many clients in R&D work and the development of new concepts...",
    remarque:
      "Our employees have the opportunity to work on projects at the cutting edge of innovation...",
    achievements: [
      {
        title: "Mixed & Augmented Reality",
        description:
          "Development of mixed reality application with loading of pre-compiled models",
        image: image12,
        link: "/2",
      },
      {
        title: "Android Dashboard",
        description:
          "Development of mixed reality application with loading of pre-compiled models",
        image: image12,
        link: "/2",
      },
    ],
  },
  {
    id: "2",
    title: "Innovation Hub",
    heroTitle: "Innovation Hub Page",

    description:
      "Our Innovation Hub has been a center of excellence for new technologies, enabling startups and businesses to collaborate...",
    remarque:
      "We continuously help our clients stay ahead in the digital era by providing solutions for new and upcoming technological disruptions.",
    achievements: [
      {
        title: "Digital Signage",
        description:
          "Development of mixed reality application with loading of pre-compiled models",
        image: image12,
        link: "/1",
      },
      {
        title: "Airplay",
        description:
          "Development of mixed reality application with loading of pre-compiled models",
        image: image12,
        link: "#",
      },
    ],
  },
];

export const JOB_OFFERS_EN = [
        {
            imageUrl: " ",
            title: "EMBEDDED LINUX DEVELOPPER",
            subtitle: "cdi",
            content: ".."
        },
        {
            imageUrl: " ",
            title: "Java DEVELOPPER",
            subtitle: "cdi",
            content: ".."
        }
];

export const OUR_HISTORY_EN = [
    {
        title: "2024",
        imageUrl: " ",
        subtitle: " ",
        content: ".."
    },
    {
        title: "2024",
        imageUrl: " ",
        subtitle: " ",
        content: ".."
    }
];

export const THE_FOUNDERS_EN = [
    {
        title: "co-manager of the company",
        subtitle: "Abdellah RSMOUKI ",
        imageUrl: " ",
        content: "JS Software developper"
    },
    {
        title: "co-manager of the company",
        subtitle: "Jamal ALLAL",
        imageUrl: image15,
        content: "C++ Software developper"
    }
];
