import React, { useEffect } from "react";
import "./Contact.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { t } from "i18next";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";



const Contact = () => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("first name is required"),
    lastName: Yup.string().required(t("last name is required")),
    email: Yup.string().required("email is required").email("email invalid"),
    message: Yup.string().required("message is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    const script = document.createElement("script");
    //script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB2KJ584PGvDDHpsYXalLHyc0JFR9e85UI&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.initMap = () => {
        const companyLocation = { lat: -34.397, lng: 150.644 };
        const map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: 8,
          center: companyLocation,
        });
        new window.google.maps.Marker({
          position: companyLocation,
          map: map,
        });
      };
    };
  }, []);

  return (
    <div className="contact container" id="contact-section">
      <div className="row align-items-end">
        <div className="col-md-6">
          <h4>{t("CONTACT_US")}</h4>
          <p>{t("CONTACT_US_BODY")}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="name">
              <div>
                <label htmlFor="first-name">{t("FIRST_NAME")}</label>
                <input
                  type="text"
                  placeholder={t("FIRST_NAME")}
                  {...register("firstName")}
                />
                <p className="error_contact">{errors.firstName?.message}</p>
              </div>
              <div>
                <label htmlFor="last-name">{t("LAST_NAME")}</label>
                <input
                  type="text"
                  placeholder={t("LAST_NAME")}
                  {...register("lastName")}
                />
                <p className="error_contact">{errors.lastName?.message}</p>
              </div>
            </div>
            <div className="adress">
              <label htmlFor="email-address">{t("EMAIL_ADRESS")}</label>
              <input
                type="text"
                placeholder={t("EMAIL_ADRESS")}
                {...register("email")}
              />
              <p className="error_contact">{errors.email?.message}</p>
            </div>
            <div className="message">
              <label htmlFor="message">{t("MESSSAGE")}</label>
              <textarea
                name="message"
                id=""
                placeholder={t("MESSSAGE")}
                {...register("message")}
              />
              <p className="error_contact">{errors.message?.message}</p>
            </div>
            <button type="submit">{t("SUBMIT")}</button>
          </form>
        </div>
        <div className="col-md-6">
          <div
            id="map"
            style={{ height: "250px", width: "100%", borderRadius: "20px" }}
          ></div>
          <div className="email">
            <div>
              <HiOutlineMail />
            </div>
            <div>
              <span>{t("EMAIL")}</span>
              <p>{t("EMAIL_BODY")}</p>
            </div>
          </div>
          <div className="phone">
            <div>
              <FiPhoneCall />
            </div>
            <div>
              <span>{t("PHONE")}</span>
              <p>{t("PHONE_BODY")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
