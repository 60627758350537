import React from "react";
import "./Our_hostory.css";
import image1 from "../../assets/img/image-1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import nextIcon from "../../assets/icons/next.png";
import prevIcon from "../../assets/icons/prev.png";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { OUR_HISTORY_AR } from "../../data/lists-ar";
import { OUR_HISTORY_EN } from "../../data/lists-en";
import { OUR_HISTORY_FR} from "../../data/lists-fr";


const OurHistory = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="our_history container">
      <div>
        <h2>{t("OUR_HISTORY")}</h2>
        <Swiper
          spaceBetween={30}
          loop={true}
          navigation={{
            nextEl: ".custom-next1",
            prevEl: ".custom-prev1",
          }}
          modules={[Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          onSlideChange={() => console.log("slide change")}
          //   onSwiper={(swiper) => console.log(swiper)}
        >
        {i18n.language === "fr" ?
          OUR_HISTORY_FR.map((el, index) => (
            <SwiperSlide key={index} style={{ width: "80%" }}>
              <div className="slide-content">
                <h4 dir="ltr">{el.title}</h4>
                <div className="container-img">
                  <img src={el.imageUrl} alt={el.title} />
                </div>
                <h3>{el.subtitle}</h3>
                <p>{el.content}</p>
              </div>
            </SwiperSlide>
          )): 
          i18n.language === "en" ?
            OUR_HISTORY_EN.map((el, index) => (
              <SwiperSlide key={index} style={{ width: "80%" }}>
                <div className="slide-content">
                  <h4 dir="ltr">{el.title}</h4>
                  <div className="container-img">
                    <img src={el.imageUrl} alt={el.title} />
                  </div>
                  <h3>{el.subtitle}</h3>
                  <p>{el.content}</p>
                </div>
              </SwiperSlide>
            )): 
            OUR_HISTORY_AR.map((el, index) => (
              <SwiperSlide key={index} style={{ width: "80%" }}>
                <div className="slide-content">
                  <h4 dir="ltr">{el.title}</h4>
                  <div className="container-img">
                    <img src={el.imageUrl} alt={el.title} />
                  </div>
                  <h3>{el.subtitle}</h3>
                  <p>{el.content}</p>
                </div>
              </SwiperSlide>
            ))
        }
        </Swiper>
        <div className="custom-navigation" dir="ltr">
          <div className="custom-prev1">
            <img src={nextIcon} alt="Next" />
          </div>
          <div className="custom-next1">
            <img src={prevIcon} alt="Previous" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurHistory;
