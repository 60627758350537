import React from 'react'
import './Footer.css'
import logo from '../../assets/img/logo.svg'
import { Link } from 'react-router-dom';
import Contact from '../../component/Contact/Contact'
import { t } from 'i18next' 

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img src={logo} alt="" />
            <p>
            DigiPattern est une société informatique spécialisée qui fournit des services professionnels 
            et des conseils dans divers domaines de l'informatique.
            </p>
          </div>
          <div className="col-md-3">
            <h5>Services</h5>
            <ul>
              <li>
                <Link to="/">Logo and Branding</Link>
              </li>
              <li>
                <Link to="/">Pay-Per-Click</Link>
              </li>
              <li>
                <Link to="/">Website Design</Link>
              </li>
              <li>
                <Link to="/">Mobile App Development</Link>
              </li>
              <li>
                <Link to="/">Search Engine Optimization</Link>
              </li>
              <li>
                <Link to="/">Social Media Marketing</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Support</h5>
            <ul>
              <li>
                <Link to="/">Contact </Link>
              </li>
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Resources</h5>
            <ul>
              <li>
                <Link to="/">Portfolio</Link>
              </li>
              <li>
                <Link to="/">Case Studies</Link>
              </li>
              <li>
                <Link to="/our_company">About</Link>
              </li>
              <li>
                <Link to="/">Our Creative Team</Link>
              </li>
              <li>
                <Link to="/">Blog</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer