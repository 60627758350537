import React from 'react'
import './Our_company.css'
import image5 from '../../assets/img/image-5.png'
import Team from '../../component/Team/Team';
import Contact from '../../component/Contact/Contact';
import icon5 from '../../assets/icons/icon5.svg'
import HeroOurCompany from '../../component/HeroOurCompany/HeroOurCompany';
import Our_hostory from '../../component/Our_hostory/Our_hostory';
import { t } from 'i18next' 

const Our_company = () => {
  return (
    <div className="our-company">
        <HeroOurCompany title={t("OUR_COMPANY")}/>
      <div className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={image5} alt="" />
            </div>
            <div className="col-md-6">
              <h4>{t("ABOUT_OUR_COMPANY")}</h4>
              {<p>{t("ABOUT_OUR_COMPANY_BODY")}</p>}
            </div>
          </div>
        </div>
      </div>
      <Our_hostory/>
      <Team />
      <section className="second-section container">
        <img src={icon5} alt="" />
        <div>
          <h4>{t("OUR_QUALITY_POLICY")}</h4>
          {<p>{t("OUR_QUALITY_POLICY_BODY")}</p>}
        </div>
        <img src={icon5} alt="" />
      </section>
      <Contact />
    </div>
  );
}

export default Our_company