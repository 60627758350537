import image12 from "../assets/img/image-12.png"
import image15 from "../assets/img/image-15.png"

export const LAB_FR = [
  {
    id: "1",
    title: "Laboratoire DigiPattern",
    heroTitle: "Page du laboratoire DigiPattern",

    description:
      "Depuis la création de notre bureau d'études DigiPattern Lab en 2014, nous avons accompagné de nombreux clients dans des travaux de R&D et le développement de nouveaux concepts...",
    remarque:
      "Nos collaborateurs ont l'opportunité de travailler sur des projets à la pointe de l'innovation...",
    achievements: [
      {
        title: "Réalité mixte et augmentée",
        description:
          "Développement d'application de réalité mixte avec chargement de modèles pré-compilés",
        image: image12,
        link: "/2",
      },
      {
        title: "Tableau de bord Android",
        description:
          "Développement d'application de réalité mixte avec chargement de modèles pré-compilés",
        image: image12,
        link: "/2",
      },
    ],
  },
  {
    id: "2",
    title: "Pôle d'innovation",
    heroTitle: "Page du pôle d'innovation",

    description:
      "Notre Innovation Hub est un centre d'excellence pour les nouvelles technologies, permettant aux startups et aux entreprises de collaborer...",
    remarque:
      "Nous aidons continuellement nos clients à garder une longueur d’avance à l’ère numérique en fournissant des solutions aux perturbations technologiques nouvelles et à venir.",
    achievements: [
      {
        title: "Affichage numérique",
        description:
          "Développement d'application de réalité mixte avec chargement de modèles pré-compilés",
        image: image12,
        link: "/1",
      },
      {
        title: "Diffusion à l'antenne",
        description:
          "Développement d'application de réalité mixte avec chargement de modèles pré-compilés",
        image: image12,
        link: "#",
      },
    ],
  },
];

export const JOB_OFFERS_FR = [
        {
            imageUrl: " ",
            title: "DÉVELOPPEUR LINUX EMBARQUÉ",
            subtitle: "cdi",
            content: ".."
        },
        {
            imageUrl: " ",
            title: "DÉVELOPPEUR JAVA",
            subtitle: "cdi",
            content: ".."
        }
];

export const OUR_HISTORY_FR = [
    {
        title: "2024",
        imageUrl: " ",
        subtitle: " ",
        content: ".."
    },
    {
        title: "2024",
        imageUrl: " ",
        subtitle: " ",
        content: ".."
    }
];

export const THE_FOUNDERS_FR = [
    {
        title: "co-gérant de la société",
        subtitle: "Abdellah RSMOUKI ",
        imageUrl: " ",
        content: "Développeur JS"
    },
    {
        title: "co-gérant de la société",
        subtitle: "Jamal ALLAL",
        imageUrl: image15,
        content: "Développeur C++"
    }
];
